
export default class Donate {
  // Constructor always gets called, pass initial params here
  constructor (_elem) {
    console.log('donate!!!')
    this.elem = _elem;
    this.config = (_elem.dataset.config) ? JSON.parse(_elem.dataset.config) : {};
    this.donateButton = this.elem.querySelector('.donate-buttons--submit');
    this.donateButtons = [];
    this.donateInput = this.elem.querySelector('.donate-buttons--input');
    this.currentAmount = 20;
    this.initialize();
  }

  initialize () {
    this.cards = document.querySelectorAll('.card');

    this.donateButtons = document.querySelectorAll('.donate-buttons--amount');

    this.addEvents();

  }

  addEvents () {
    this.donateButton.onclick = (e) => {
      e.preventDefault();
      this.donate();
    }

    for (const btn of this.donateButtons) {
      btn.onclick = (e) => {
        console.log(e.target.dataset.amount);
        this.setActiveButton(e.target);
      }
    }

    console.log(this.donateInput);
    this.donateInput.oninput = (e) => {
      console.log('this', e.target.value);
      this.currentAmount = e.target.value;
      this.updateButton();
      this.deselectButtons();
    }
  }

  deselectButtons () {
    for(const btn of this.donateButtons) {
      btn.classList.remove('active');
    }
  }

  setActiveButton (trgt) {
    this.currentAmount = trgt.dataset.amount;
    this.deselectButtons();
    trgt.classList.add('active');
    this.updateButton()
  }

  updateButton() {
    this.donateButton.innerHTML = `DONATE € ${this.currentAmount},- NOW`;
  }

  donate () {
    switch(this.currentAmount) {
      case 10:
        window.open('https://useplink.com/payment/G1HpNOH2BRVr6aMEK72D/', '_blank');
        break;
        case 20:
          window.open('https://useplink.com/payment/zIrLlYz5IgHQ7NC7omFK/', '_blank');
          break;
        case 50:
          window.open('https://useplink.com/payment/qqXv2CvKLAP2RwtVCTpZ/', '_blank');
          break;
        default:
          window.open(`https://useplink.com/payment/RidtebYiHnVWedDLjoYz/EUR${this.currentAmount}`, '_blank');
    }
  }

}

import ElementToggle from './modules/toggle/ElementToggle';
import Searchbox from './elements/EmptySearchbox';
import SocialShare from './elements/SocialShare';
import Tabs from './components/tabs/Tabs';
import Gallery from './components/gallery/gallery';
import Donate from './components/donate/donate';
import HeroLoader from './components/heroloader/HeroLoader'
import Mailform from './components/mailform/mailform';
import Accordion from './components/accordion/Accordion';
import * as WebFont from 'webfontloader';
import inView from 'in-view';

/**
 *
 * WebfontLoader
 *
 */

WebFont.load({
  google: {
    families: ['Josefin Sans:100,300,700']
  }
});

const toggles = document.querySelectorAll('[data-module="toggle"]');
console.log('hier', toggles)
for (let toggle of toggles) {
  console.log('hier', toggle)
  toggle = new ElementToggle(toggle);
}


const galleries = document.querySelectorAll('[data-module="gallery"]');

for (let gallery of galleries) {
  gallery = new Gallery(gallery);
}

const donates = document.querySelectorAll('[data-module="donate"]');

for (let donate of donates) {
  donate = new Donate(donate);
}


const heroes = document.querySelectorAll('[data-module="heroloader"]');
for (let hero of heroes) {
  hero = new HeroLoader(hero);
}


const mailforms = document.querySelectorAll('#mc_embed_signup');
for (let mailform of mailforms) {
  mailform = new Mailform(mailform);
}

/**
 *
 * A searchbox with reset button
 * @param -
 * @returns
 *
 */

const searchboxes = document.querySelectorAll('[data-module="searchbox"]');

for (let searchbox of searchboxes) {
  searchbox = new Searchbox(searchbox);
}

/**
 *
 * Social Share buttons
 *
 */

const shareIcons = document.querySelectorAll('[data-module="socialshare"]');

for (let shareIcon of shareIcons) {
  shareIcon = new SocialShare(shareIcon);
}

/**
 *
 * A tab component
 *
 */

const tabElements = document.querySelectorAll('[data-module="tabs"]');

for (let tabs of tabElements) {
  tabs = new Tabs(tabs);
}

/**
 *
 * An accordion component
 *
 */

const accordions = document.querySelectorAll('[data-module="accordion"]');

for (let accordion of accordions) {
  accordion = new Accordion(accordion);
}


inView('.section')
  .on('enter', (el) => {
    el.classList.add('inview');
  })
  .on('exit', (el) => {
    el.classList.remove('inview');
  });


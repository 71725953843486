
export default class Gallery {
  // Constructor always gets called, pass initial params here
  constructor (_elem) {
    console.log('gallery!!!')
    this.elem = _elem;
    this.config = (_elem.dataset.config) ? JSON.parse(_elem.dataset.config) : {};
    this.activeClass = this.config.activeClass;
    this.isOpen = false;
    this.cards = [];
    this.cardData = [];
    this.currentCardIndex = 0;
    this.galleryWrapper = '';
    this.touchStartX = 0;
    this.touchEndX = 0;
    this.initialize();
  }

  initialize () {
    this.cards = document.querySelectorAll('.card');
    for (const card of this.cards) {
      console.log(card.dataset.image);
      const cardData = {
        title: card.dataset.title,
        image: card.dataset.image,
        name: card.dataset.name,
        age: card.dataset.age,
        country: card.dataset.country,
        description: card.dataset.description
      }
      this.cardData.push(cardData);
    }
    this.addEvents();
    this.createGallery();
  }

  addEvents () {
    for (const card of this.cards) {
      card.onclick = (e) => this.handleCardClick(e);
    }
  }



  handleCardClick (e) {
    e.preventDefault();

    for (const [index, crd] of this.cards.entries()) {
      if (crd === e.currentTarget) {
        this.currentCardIndex = index;
      }
    }

    this.openGallery (this.currentCardIndex);
  }

  createGallery () {

    const wrapper = document.createElement('div');
    wrapper.className = 'gallery--wrapper';
    wrapper.style.display = 'none';

    const wrapperOverlay = document.createElement('div');
    wrapperOverlay.className = 'overlay';
    wrapper.appendChild(wrapperOverlay);

    const itemsWrapper = document.createElement('div');
    itemsWrapper.className = 'items-wrapper';
    itemsWrapper.style.width = window.innerWidth + 'px';
    wrapper.appendChild(itemsWrapper);

    const itemsWrapperInner = document.createElement('div');
    itemsWrapperInner.className = 'items-wrapper__inner';
    itemsWrapperInner.style.width = (window.innerWidth) * (this.cards.length) + 'px';
    itemsWrapper.appendChild(itemsWrapperInner);

    let htmlString = '';
    let buttonHTML = document.createElement('div');
    buttonHTML.id = 'gallery-buttons';
    buttonHTML.innerHTML = `<div class="gallery-buttons__inner">
      <div id="left" class="gallery-nav-button gallery-nav-button--left">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
      </div>
      <div id="right" class="gallery-nav-button gallery-nav-button--right">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
      </div>
      </div>`;

       /* eslint-disable */
      let closeHTML = document.createElement('div');
      closeHTML.id = 'btn_close'
      closeHTML.className = 'btn_close';
      closeHTML.innerHTML = `<div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></div>`
      /* eslint-enable */

    for (const item of this.cardData) {
      /* eslint-disable */
      const {title, image, name, age, country} = item;
      htmlString += `
        <div class="gallery-item" style="width:${window.innerWidth}px; height:${window.innerHeight}px;">
          <div class="gallery-item__inner">
            <div class="image--wrapper">
              <span><img src="${image}"></span>
                <div class="content--wrapper">
                <div class="title">${title}, &nbsp;</div>
                  <div class="content__information">

                    <div class="name">${name}</div>
                    <div class="age">, ${age} ${age ? 'years old,&nbsp;': ''}</div>
                    <div class="country">from ${country}</div>
                  </div>
                  <div class="content__share">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share-2"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                  </div>
                </div>
              </div>
            </div>
        </div>
      `;
    }
    /* eslint-enable */
    itemsWrapperInner.innerHTML = htmlString;

    document.body.appendChild(wrapper);
    wrapperOverlay.appendChild(buttonHTML);
    wrapperOverlay.appendChild(closeHTML);

    this.galleryWrapper = wrapper;
    this.addButtonEvents();
  }

  addButtonEvents () {
    const left = document.getElementById('left');
    const right = document.getElementById('right');
    const wrapper = document.querySelector('.gallery--wrapper');
    const imageWrapper = document.querySelectorAll('.image--wrapper');
    const btnWrp = document.getElementById('gallery-buttons');
    const btnClose = document.getElementById('btn_close');

    left.onclick = (e) => {
      e.stopImmediatePropagation();
      this.setCurrentIndex(this.currentCardIndex - 1);
      this.navigateTo(this.currentCardIndex);
    }

    right.onclick = (e) => {
      e.stopImmediatePropagation();
      this.setCurrentIndex(this.currentCardIndex + 1);
      this.navigateTo(this.currentCardIndex);
    };

    document.onkeydown = (e) => {
      if (e.key === 'Escape') {
        this.closeGallery();
      }
      if (e.key === 'ArrowLeft') {
        this.setCurrentIndex(this.currentCardIndex - 1);
        this.navigateTo(this.currentCardIndex);
      }
      if (e.key === 'ArrowRight') {
        this.setCurrentIndex(this.currentCardIndex + 1);
        this.navigateTo(this.currentCardIndex);
      }
    };

    wrapper.onclick = () => this.closeGallery();
    btnWrp.onclick = () => this.closeGallery();


    for (const imWrp of imageWrapper) {
      imWrp.onclick = (e) => {
        e.stopImmediatePropagation();
      }
    }

    btnClose.onclick = () => {
      this.closeGallery();
    }

    document.ontouchstart = (e) => {
      this.handleStart(e);
    }

    document.ontouchmove = (e) => {
      this.handleMove(e);
    }

    document.ontouchend = (e) => {
      this.handleEnd(e);
    }
  }

  handleStart(e) {
    this.touchStartX = e.touches[0].screenX;
    console.log('start', e.touches[0].screenX);
  }

  handleMove (e) {
    this.touchEndX = e.changedTouches[0].screenX;
  }

  handleEnd() {
    if(Math.abs(this.touchEndX - this.touchStartX) > 40) {
      if  (this.touchEndX < this.touchStartX) {
        this.setCurrentIndex(this.currentCardIndex + 1);
        this.navigateTo(this.currentCardIndex);
      } else {
        this.setCurrentIndex(this.currentCardIndex - 1);
        this.navigateTo(this.currentCardIndex);
      }
    }
  }

  setCurrentIndex (index) {
    if (index < 0) {
      this.currentCardIndex = 0;
    } else if (index > (this.cards.length - 1)) {
      this.currentCardIndex = this.cards.length - 1;
    } else {
      this.currentCardIndex = index;
    }

    const left = document.getElementById('left');
    const right = document.getElementById('right');

    if(this.currentCardIndex >= (this.cards.length - 1)) {
      right.classList.add('inactive');
    } else {
      right.classList.remove('inactive');
    }

    if (this.currentCardIndex <= 0) {
      left.classList.add('inactive');
    } else {
      left.classList.remove('inactive');
    }
  }

  closeGallery () {
    this.galleryWrapper.style.display = 'none';
  }

  openGallery (index = 0) {
    this.setCurrentIndex(index);
    this.navigateTo(index);
    this.galleryWrapper.style.display = 'block';
  }

  navigateTo (index) {
    console.log(index);
    const el = document.querySelector('.items-wrapper__inner');
    const perc = (100 / this.cards.length) * index;

    el.style.transform = `translateX(-${(100 / this.cards.length) * index});`;
    el.style.transform = 'translateX(-' + perc + '%)';
  }
}


export default class HeroLoader {
  // Constructor always gets called, pass initial params here
  constructor (_elem) {
    console.log('heroloader')
    this.elem = _elem;
    this.config = (_elem.dataset.config) ? JSON.parse(_elem.dataset.config) : {};
    this.activeClass = this.config.activeClass;
    this.isLoaded = false;
    this.xhr;
    this.loaderProgress = document.querySelector('.homepage-loader__progress');
    this.initialize();
  }

  initialize () {
    if(!window.URL.createObjectURL) {
        //$element.css('background-image','url('+ config.imgSrc +')');
      return;
    }

    this.loadHero();
  }

  loadHero () {
    const that = this;
    console.log(that);
    const xhr = new XMLHttpRequest;
    xhr.open('GET', this.config.image, true);
    xhr.responseType = 'blob';
    xhr.onprogress = (e) => {
      const percentageLoaded = Math.round((e.loaded/e.total) * 100);
      console.log(percentageLoaded);
      this.loaderProgress.innerHTML = `${Math.round(percentageLoaded)}%`;
    };

    xhr.onreadystatechange = (e) => {
      if(e.target.readyState === 4) {
        setTimeout(() => {
          this.showHero();
        }, 500);
      }
    };

    xhr.send();
  }

  showHero () {
    const loaderVisual = document.querySelector('.homepage-loader');
    const buttonBar = document.querySelector('.homepage-hero__buttons');
    const intro = document.querySelector('.homepage-hero__intro');
    loaderVisual.classList.add('loaded');
    buttonBar.classList.add('loaded');
    intro.classList.add('loaded');
  }
}
